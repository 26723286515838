import { render, staticRenderFns } from "./OrganizationInfos.vue?vue&type=template&id=bfd34cb4&scoped=true&"
import script from "./OrganizationInfos.vue?vue&type=script&lang=ts&"
export * from "./OrganizationInfos.vue?vue&type=script&lang=ts&"
import style0 from "./OrganizationInfos.vue?vue&type=style&index=0&id=bfd34cb4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_vue-template-compiler@2.7.16_webpack@5.94.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfd34cb4",
  null
  
)

export default component.exports